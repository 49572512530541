<template>
  <div class="container">
    <div class="row center-xs">
      <div class="col-sm-12">
        <h2 class="mb5 step-title">
          {{ $t('Delivery') }}
        </h2>
      </div>
      <div class="col-sm-12">
        <h2 class="mt0 mb20 step-subtitle">
          {{ $t('Choose how to deliver products for sale to e-garderobe') }}
        </h2>
      </div>
      <div class="col-sm-3 mx20 my10 pl15 box" :class="{checked: shipping.code === getCurrentShippingCode}" v-for="shipping in shippings" :key="shipping.id" @click="chooseShipping(shipping)">
        <div class="flex between-xs my10 mr10">
          <div class="dot">
            <div v-if="shipping.code === getCurrentShippingCode" class="dot-full" />
          </div>
          <p class="fs-medium-small m0 weight-700">
            {{ shipping.price | price(storeView) }}
          </p>
        </div>
        <div class="pr15">
          <h5 class="my5 secondary-font align-left">
            {{ shipping.name }}
          </h5>
          <p class="fs12 mt0 align-left paragraph">
            {{ shipping.desc }}
          </p>
        </div>
      </div>
    </div>
    <div class="row center-xs">
      <button-full class="col-xs-12 mt55 next-button" @click.native="goNextStep">
        {{ $t('Next') }}
      </button-full>
    </div>
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n'
import ButtonFull from 'theme/components/theme/ButtonFull'
import { mapGetters } from 'vuex'
import { isCanBeReached } from 'theme/helpers/selling-products'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  name: 'DeliveryStep',
  components: {
    ButtonFull
  },
  computed: {
    ...mapGetters({
      getCurrentShippingCode: 'selling/getCurrentShippingCode',
      getShippingName: 'selling/getShippingName'
    }),
    shippings () {
      return [
        {
          name: this.getShippingName('dhl', this.langCode),
          code: 'dhl',
          price: 25,
          desc: i18n.t('After successful verification of the products, you will receive a link to the DHL courier fee and an already addressed shipping label to your email address.')
        },
        {
          name: this.getShippingName('self_service', this.langCode),
          code: 'self_service',
          price: 0,
          desc: i18n.t('Once the products have been successfully verified, you will receive a confirmation to your email address that you can bring the items to the E-Garderobe showroom in Warsaw.')
        }
      ]
    },
    storeView () {
      return currentStoreView()
    },
    langCode () {
      return this.storeView.i18n.defaultLocale.replace('-', '_')
    }
  },
  methods: {
    chooseShipping (shipping) {
      this.$store.commit('selling/setShippingCode', shipping.code)
    },
    goNextStep () {
      if (this.getCurrentShippingCode) {
        this.$store.dispatch('selling/afterShippingSection')
        if (isCanBeReached('confirm')) this.$store.dispatch('selling/goToStep', 'confirm')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-silver: color(silver);
  $color-black: color(black);

  .step-title {
    margin-top: 40px;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1.94px;
    text-transform: uppercase;
  }
  .step-subtitle {
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1.32px;
    text-transform: uppercase;
  }
  .box {
    cursor: pointer;
    min-width: 290px;
    max-width: 290px;
    border: 1px solid $color-silver;
    border-radius: 9px;
    &:hover {
      border: 1px solid $color-black;
    }
  }
  .dot {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    border: 1px black solid;
    border-radius: 50%;
  }
  .dot-full {
    width: 10px;
    height: 10px;
    border: 1px black solid;
    background-color: black;
    border-radius: 50%;
  }
  .next-button {
    max-width: 300px;
  }
  @media (max-width: 767px) {
    .next-button {
      margin: 20px 0;
    }
    .step-title {
      font-size: 15px;
      letter-spacing: 1.32px;
    }
    .step-subtitle {
      font-size: 12px;
      letter-spacing: 1.06px;
      line-height: 25px;
      margin-bottom: 5px;
    }
  }
</style>
